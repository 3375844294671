import React from "react"

type PropTypes = {
  status: "error" | "success" | "warning" | "info"
  errorMessage: string
  nrHover: number
  doesFormHaveErrors: boolean
  setNrHover: (nr: number) => void
}

export default function FormeErrorLine({
  status,
  errorMessage,
  nrHover,
  setNrHover,
  doesFormHaveErrors,
}: PropTypes) {
  return (
    <div className="ncsRow">
      <div className="ncsErrorMesage" onMouseEnter={() => setNrHover(2)}>
        {doesFormHaveErrors && nrHover > 1 ? errorMessage : ""}
      </div>
    </div>
  )
}
