import React from "react"
import Button from "../Button"
import { calcFilesSize } from "../Cereri/MultiFileUploader"
import svgs from "../Dash/svgs"
import { useFileInput } from "../Hooks/useFileInput"

type PropTypes = {
  id: string
  buttonLabel: string
  fileInputRef: React.RefObject<HTMLInputElement>
  changeFiles: (event: React.ChangeEvent<HTMLInputElement>) => void
  removeFile: (name: string, index: number) => void
  files: File[]
  hideButton?: boolean
}

export default function NewFormFileInput({
  id,
  buttonLabel,
  fileInputRef,
  changeFiles,
  removeFile,
  files,
  hideButton = false,
}: PropTypes) {
  return (
    <div className="fileInputContainer">
      <input
        type="file"
        id={id}
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={changeFiles}
        multiple
        accept="image/*,.pdf"
      />

      {!hideButton && (
        <Button
          as="button"
          aspect="noBackground"
          className="ncsButton withIcon"
          onClick={() => fileInputRef.current?.click()}
          // label={buttonLabel}
        >
          <span className="label">{buttonLabel}</span>
          <span className="icon">{svgs.newAddFile}</span>
        </Button>
      )}

      <div className="fiFileList">
        {files.map((file, index) => (
          <div key={index} className="filItem">
            <span className="fileName">{file.name}</span>
            <span
              className="fileRemove"
              onClick={() => removeFile(file.name, index)}
            >
              {svgs.closeIcon}
            </span>
          </div>
        ))}
      </div>

      <div
        className={
          "dimensiune" + (calcFilesSize(files) > 14.99 ? " colorRed" : " ")
        }
      >
        {files.length > 0
          ? `Dimensiune: ${calcFilesSize(files).toLocaleString("ro-RO", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })} MB`
          : ""}
        {files.length > 0 && <br />}
        Documentele trebuie să aibă maxim 15mb și să fie formatul pdf/jpeg/png.
      </div>
    </div>
  )
}
