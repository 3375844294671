import React from "react"

type PropTypes = {
  containerClassName?: string
  labelClassName?: string
  labelText: string

  id: string
  checked: boolean
  onChange: () => void
  showError: boolean
}
export default function NewCheckBox({
  containerClassName = "",
  labelClassName = "",
  labelText,
  id,
  checked,
  showError,
  onChange,
}: PropTypes) {
  return (
    <div className={"newCheckBox " + containerClassName}>
      <input
        type="checkbox"
        id={id}
        name={id}
        style={{ float: "left", marginTop: 4 }}
        checked={checked}
        onChange={onChange}
      />
      <label
        className={
          "newCereriCheckInputLabel " +
          labelClassName +
          (showError ? " error" : "")
        }
        htmlFor={id}
        // onClick={onChange}
      >
        <span>{labelText}</span>
      </label>
    </div>
  )
}
