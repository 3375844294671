import React from "react"

type PropTypes = {
  containerClassName?: string
  id: string
  checked: boolean
  onChange: () => void
  showError: boolean
}
export default function CheckBoxAgreeToTerms({
  containerClassName = "",
  id,
  checked,
  showError,
  onChange,
}: PropTypes) {
  return (
    <div className="newCheckBoxAgreeToTermsContainer">
      <input
        type="checkbox"
        id={id}
        name={id}
        style={{ float: "left", marginTop: 4 }}
        checked={checked}
        onChange={onChange}
      />
      <label
        className={
          "newCereriCheckInputLabel " +
          containerClassName +
          (showError ? " error" : "")
        }
        htmlFor={id}
        // onClick={onChange}
      >
        <span className={containerClassName}>
          Sunt de accord cu{" "}
          <a target="_blank" href="/documente/Terms&Conditions.pdf">
            <span>
              <b>termeni și condițiile de utilizare</b>
            </span>
          </a>{" "}
          și{" "}
          <a target="_blank" href="/documente/PrivacyPolicy.pdf">
            <span>
              <b>politica de confidențialitate</b>
            </span>
          </a>
          .
        </span>
      </label>
    </div>
  )
}
