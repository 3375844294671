import { apiBase, ApiResponse, checkStatus } from "./utils"

const baseUrl = `${apiBase}/api/cerereContractFurnziare`

export type DataCerereContractFurnizare = {
  accountId?: string
  isCasnic: boolean
  denumire: string
  cuicnp: string
  adresa: string
  localitate: string
  judet: string
  numeReprezentant: string
  email: string
  telefon: string
  locConsum: {
    isSame: boolean
    adresa?: string
    localitate?: string
    judet?: string
  }
  nrCadastral?: string
  clc?: string
  accordContractPeEmail: boolean
  acordDate: boolean
}

interface PostCerereContractFurnizareResponse extends ApiResponse<null> {
  filesToLarge: boolean
}

export function postCerereContractFurnizare(
  data: FormData
): Promise<PostCerereContractFurnizareResponse> {
  const loginData = {
    method: "post",
    headers: {
      // azza: getToken(),
      Accept: "application/json",
    },
    body: data,
  }
  return fetch(baseUrl, loginData).then(
    checkStatus
  ) as Promise<PostCerereContractFurnizareResponse>
}
