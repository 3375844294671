import React from "react"

import "./styles.css"
export type ActNecesar = {
  title: string
  subItems?: string[]
}

type PropTypes = {
  items: ActNecesar[]
  withoutText?: boolean
}

export default function ActeNecesare({
  items,
  withoutText = false,
}: PropTypes) {
  return (
    <div className="anContainer">
      {!withoutText && (
        <div className="text">
          <div className="title">Acte necesare</div>
          <div className="subTitle">Imagini sau format .pdf</div>
        </div>
      )}

      <ul className="anList">
        {items.map((item, index) => (
          <li key={index}>
            {item.title}
            {item.subItems != null && item.subItems.length > 0 && (
              <ul className="anListItemSubList">
                {item.subItems.map((subItem, index) => (
                  <li key={index}>{subItem}</li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
}
