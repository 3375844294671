import React from "react"

export interface I_NewTabItem {
  id: number
  title: string
  icon?: React.ReactNode
  iconClassName?: string
}
type PropTypes = {
  containerClassName?: string
  title?: string
  subTitle?: string
  items: I_NewTabItem[]
  idTabSelected: number
  setIdTabSelected: (id: number) => void
}

export default function NewTabs({
  containerClassName = "",
  title,
  subTitle,
  items,
  idTabSelected,
  setIdTabSelected,
}: PropTypes) {
  return (
    <div className={"newTabContainer " + containerClassName}>
      {(title != null || subTitle != null) && (
        <div className="text">
          {title != null && <div className="title">{title}</div>}
          {subTitle != null && <div className="subTitle">{subTitle}</div>}
        </div>
      )}
      <div className="newStyleTabs">
        {items.map(item => (
          <div
            key={item.id}
            className={`tabItem ${
              item.id === idTabSelected ? "ncsSelectedBorder" : "ncsShadow"
            }`}
            onClick={() => setIdTabSelected(item.id)}
          >
            <div className={"icon " + item.iconClassName}>{item.icon}</div>
            <div className="name">{item.title}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
